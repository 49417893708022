export const mapStyles = [
	{pro: true, name:'Outdoors', id: 'terrain', url:'mapbox://styles/puretrack/cl1fzny7k005d14npzddq7m7k', dark: false},
	{pro: true, name:'Dark', id: 'dark', url:'mapbox://styles/puretrack/cl1fzh220004s16s0sbfetpoj', dark: true},
	{pro: true, name:'Light', id: 'light', url:'mapbox://styles/puretrack/cl1fzolix001p15runlyvs1y1', dark: false},
	{pro: true, name:'Satellite', id: 'satellite', url:'mapbox://styles/puretrack/cl1fzpw4z001j14odq8m3re9m', dark: true},
	{pro: true, name:'Positron', id: 'positron', url:'https://basemaps.cartocdn.com/gl/positron-gl-style/style.json', dark: false},
	{pro: false, name:'ICGC Terrain', id: 'icgc-terrain', url:'https://geoserveis.icgc.cat/contextmaps/icgc_mapa_estandard.json', dark: false},
	{pro: true, name:'Dark Matter', id: 'darkmatter', url:'https://basemaps.cartocdn.com/gl/dark-matter-gl-style/style.json', dark: true},
	{pro: false, name:'Voyager', id: 'voyager', url:'https://basemaps.cartocdn.com/gl/voyager-gl-style/style.json', dark: false},
	{pro: false, name: 'Open Street Map', id: 'osm', dark: false, url: 
		{
			version: 8,
			glyphs: "https://fonts.openmaptiles.org/{fontstack}/{range}.pbf",
			sources: {
				osm: {
				type: 'raster',
				tiles: ["https://tile.openstreetmap.org/{z}/{x}/{y}.png"],
				tileSize: 256,
				attribution: 'Map tiles by <a target="_top" rel="noopener" href="https://tile.openstreetmap.org/">OpenStreetMap tile servers</a>, under the <a target="_top" rel="noopener" href="https://operations.osmfoundation.org/policies/tiles/">tile usage policy</a>. Data by <a target="_top" rel="noopener" href="http://openstreetmap.org">OpenStreetMap</a>'
				}
			},
			layers: [{
				id: 'osm',
				type: 'raster',
				source: 'osm',
			}],
			
		}
	},
	{pro: true, name: 'Open AIP', id: 'openaip', dark: false, url: 
		{
			version: 8,
			glyphs: "https://fonts.openmaptiles.org/{fontstack}/{range}.pbf",
			sources: {
				oaip: {
					type: 'raster',
					tiles: ["https://api.tiles.openaip.net/api/data/openaip/{z}/{x}/{y}.png?apiKey=b02b33958b3588b84c83d45a24575151"],
					tileSize: 256,
					attribution: 'OpenAIP <a href="https://www.openaip.net">https://www.openaip.net</a>'
				}
			},
			layers: [{
				id: 'openaip',
				type: 'raster',
				source: 'oaip',
			}],
			
		}
	}
]